<template>
  <section class="trf-contact" v-if="contactData">
    <div class="container">
      <div class="contact-page animate__animated animate__fadeInUp">
        <div class="col-12">
          <h2 v-html="parsedMarkdown(contactData.Title)"></h2>
          <div class="divider"></div>
          <p>
            {{ contactData.Description }}
          </p>
        </div>

        <div class="col-12 pt-3 pt-md-5">
          <div class="row justify-content-center">
            <div class="col-12 col-md-5 d-flex justify-content-end">
              <form class="contact-page__form">
                <label for="">
                  <span>Nombres completos</span>
                  <v-text-field
                    density="compact"
                    variant="solo-filled"
                    v-model="names"
                    placeholder="Ingresa tusnombres"
                    clearable
                  ></v-text-field>
                </label>

                <label for="">
                  <span>Correo electrónico</span>
                  <v-text-field
                    density="compact"
                    variant="solo-filled"
                    v-model="email"
                    placeholder="Ingresa tu correo"
                    clearable
                  ></v-text-field>
                </label>

                <label for="">
                  <span>Teléfono</span>
                  <v-text-field
                    density="compact"
                    variant="solo-filled"
                    v-model="phone"
                    placeholder="Ingresa tu número"
                    clearable
                  ></v-text-field>
                </label>

                <label for="">
                  <span>Mensaje</span>
                  <v-textarea
                    density="compact"
                    variant="solo-filled"
                    v-model="detail"
                    placeholder="Escribe un mensaje..."
                    required
                    clearable
                  ></v-textarea>
                </label>

                <div class="box-recaptcha">
                  <vueRecaptcha
                    sitekey="6Le__SgqAAAAAAuaXL6Em6hmtmf5Z57BzOPBUYPo"
                    size="normal"
                    theme="light"
                    @verify="recaptchaVerified"
                  >
                  </vueRecaptcha>
                </div>

                <button
                  class="submt"
                  v-bind:class="
                    (names === '' ||
                      email === '' ||
                      phone === '' ||
                      tokenVerify === '' ||
                      detail === '') &&
                    'disabled'
                  "
                  :disabled="
                    names === '' ||
                    email === '' ||
                    phone === '' ||
                    tokenVerify === '' ||
                    detail === ''
                  "
                  type="submit"
                  @click="doSubmit($event)"
                >
                  Cotizar servicio
                </button>
              </form>
            </div>
            <div class="col-12 col-md-5 pt-5 pt-md-0 contact-page__data">
              <p>
                <img src="../../../images/icons/phone-footer.png" alt="" />
                <span>
                  <a
                    :href="
                      'https://wa.me/+51972077832'
                    "
                  >
                    {{ contactData.Components[0].Cellphone }}
                  </a>
                </span>
              </p>
              <p>
                <img src="../../../images/icons/mail-footer.png" alt="" />
                <span>
                  <a :href="'mailto:' + contactData.Components[0].Email">
                    {{ contactData.Components[0].Email }}
                  </a>
                </span>
              </p>

              <div class="pb-3 pb-md-0"></div>
              <span>{{ contactData.Components[0].Address }}</span>

              <div class="gg-map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d836.0696850000526!2d-81.26453257396102!3d-4.58683067026632!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sAv.%20%22F%22%20-%20Mza%20A%20Lote%2001%20A.H.%20Luciano%20Castillo%20-%20Talara!5e0!3m2!1ses-419!2spe!4v1712774192329!5m2!1ses-419!2spe"
                  width="100%"
                  height="100%"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import vueRecaptcha from "vue3-recaptcha2";
import { onMounted, ref } from "vue";
import { useQuery } from "villus";
import MarkdownIt from "markdown-it";

const markdown = new MarkdownIt();
const activeId = ref(null);
const response = ref("");
const names = ref("");
const email = ref("");
const phone = ref("");
const detail = ref("");
const tokenVerify = ref("");
const parsedMarkdown = (val) => markdown.render(val);
const pageQuery = `query Pages {
    pages(filters: { PageName: { eq: "Contáctanos" } }) {
        data {
            id
            attributes {
                PageName
                Slug
                Title
                Description
                MetaDescription
                MetaKeywords
                createdAt
                updatedAt
                publishedAt
                Components {
                    ... on ComponentPageSecContact {
                        id
                        Cellphone
                        Email
                        Address
                        Maps
                    }
                }
            }
        }
    }
}
`;

const contactData = ref(null);

onMounted(async () => {
  const { data } = await useQuery({
    query: pageQuery,
  });

  const pageData = data._rawValue.pages.data[0].attributes;
  contactData.value = pageData;
});

const doSubmit = (event) => {
  event.preventDefault();
  if (
    names.value &&
    email.value &&
    phone.value &&
    detail.value &&
    tokenVerify.value
  ) {
    let text = "Hola Transportes Rafael:%0A %0A";
    text += "Nombres completos: " + names.value + "%0A";
    text += "Correo electrónico: " + email.value + "%0A";
    text += "Teléfono: " + phone.value + " %0A";
    text += "Mensaje: " + detail.value + " ";
    const url = `https://wa.me/+51908898445?text=${text}`;
    window.open(url, "_blank");

    setTimeout(() => {
      names.value = "";
      email.value = "";
      phone.value = "";
      detail.value = "";
      tokenVerify.value = "";
    }, 1000);
  }
};

const recaptchaVerified = ($event) => {
  tokenVerify.value = $event;
};
</script>

<style lang="scss">
.gg-map {
  width: 100%;
  height: 300px;
}
.box-recaptcha {
  display: flex;
  transform: scale(0.94);
  justify-content: center;
  padding-top: 14px;
}
.trf-contact {
  width: 100%;
  .trf-clients {
    background: var(--bg-background);
  }
}

.submt {
  background-color: #00c2cb;
  text-decoration: none;
  color: white;
  padding: 12px 15px 12px 18px;
  border-radius: 30px;
  font-weight: 500;
  font-size: 18px;
  width: 167px;
  font-size: 16px;
  text-align: center;
  padding: 12px 16px !important;
  margin: 1rem auto;
  margin-bottom: 0;
  border: none;
  &.disabled {
    background: gray !important;
    opacity: 0.4 !important;
  }
}

.contact-page {
  width: 100%;
  min-height: 550px;
  padding: 5rem 0;
  > div > h2 {
    p {
      font-size: 50px;
      font-weight: 300;
      text-align: center;
      color: var(--bg-primary);
      b,
      strong {
        font-weight: 600;
      }
    }
  }
  > div > p {
    padding: 0.7rem 0 1.4rem;
    line-height: 1.2;
    text-align: center;
    font-size: 18px;
    width: 600px;
    color: #012d46;
    margin: 0 auto;
  }
  .divider {
    width: 200px;
    background: #005f96;
    height: 3px;
    margin: 1rem auto;
  }

  &__form {
    width: 350px;
    height: auto;
    background: #005f96;
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 1.4rem 0 2rem;
    label {
      color: #ffff;
      display: grid;
    }
    .cc-button {
      width: 167px;
      font-size: 16px;
      text-align: center;
      padding: 12px 16px !important;
      margin: 1rem auto;
      margin-bottom: 0;
      img {
        display: none !important;
      }
    }
  }

  &__data {
    p {
      margin-bottom: 1.4rem;
      img {
        width: 40px;
      }
      span,
      a {
        color: #012d46;
        text-decoration: none;
        padding: 0 1rem;
        font-weight: 600;
        font-size: 16px;
      }
      span {
        a {
          padding: 0;
        }
      }
    }

    > span {
      font-size: 16px;
      line-height: 1;
    }
  }

  form {
    padding: 1.5rem 2rem;
    label {
      font-size: 13px;
    }
    input,
    textarea {
      font-size: 12px;
      color: black;
    }
  }
  button[type="submit"] {
    color: #fff;
    text-transform: capitalize;
    font-weight: 800;
  }
}

@media (min-width: 360px) and (max-width: 800px) {
  .contact-page {
    padding: 3rem 0;
  }
  .contact-page > div > h2 p {
    font-size: 30px;
  }
  .contact-page > div > p {
    font-size: 18px;
    width: auto;
  }
  .contact-page .divider {
    width: 150px;
    background: #005f96;
    height: 2px;
    margin: 0.3rem auto;
  }
  .contact-page__form {
    width: 100%;
  }
  .contact-page__data p img {
    width: 30px;
  }
}
</style>
