<template>
  <section class="trf-quotation d-flex align-items-center">
    <div
      class="container justify-content-center align-content-center align-items-center d-flex"
    >
      <form
        action="javascript:;"
        class="mt-4 animate__faster animate__animated animate__fadeInUp"
      >
        <div class="form-wrapper px-8 px-md-2 px-md-5">
          <img
            src="../../../images/icons/close.svg"
            @click="$emit('close')"
            alt=""
          />
          <div class="row">
            <h2 class="text-center text-white">
              Solicita una <b>cotización</b>
            </h2>
            <div class="divider"></div>
            <p class="text-center text-white d-md-block">
              Obtén una cotización sobre cualquiera de nuestros servicios, debes
              completar todos los campos
            </p>
          </div>

          <div class="row form-inputs">
            <div class="col-12 col-md-6">
              <label for="">
                <span>Nombres completos</span>
                <v-text-field
                  density="compact"
                  variant="solo-filled"
                  v-model="names"
                  placeholder="Ingresa tus nombres"
                  clearable
                ></v-text-field>
              </label>
            </div>

            <div class="col-12 col-md-6 d-md-block">
              <label for="">
                <span>Nombre de empresa</span>
                <v-text-field
                  density="compact"
                  variant="solo-filled"
                  v-model="company"
                  placeholder="Ejem: Petro Perú"
                  clearable
                ></v-text-field>
              </label>
            </div>

            <div class="col-12 col-md-6 d-md-block">
              <label for="">
                <span>Correo electrónico</span>
                <v-text-field
                  density="compact"
                  variant="solo-filled"
                  v-model="email"
                  placeholder="Ingresa tu correo"
                  clearable
                ></v-text-field>
              </label>
            </div>

            <div class="col-12 col-md-6">
              <label for="">
                <span>Teléfono</span>
                <v-text-field
                  density="compact"
                  variant="solo-filled"
                  v-model="phone"
                  placeholder="Ingresa tu número"
                  clearable
                ></v-text-field>
              </label>
            </div>

            <div class="col-12 col-md-6">
              <label for="">
                <span>Tipo de servicio</span>
                <v-text-field
                  density="compact"
                  variant="solo-filled"
                  v-model="service"
                  placeholder="Ejem: Transporte de personal"
                  clearable
                ></v-text-field>
              </label>
            </div>

            <div class="col-12 col-md-6">
              <label for="">
                <span>Cantidad de pasajeros</span>
                <v-text-field
                  density="compact"
                  variant="solo-filled"
                  v-model="quantity"
                  placeholder="Ejem: 14 personas"
                  clearable
                ></v-text-field>
              </label>
            </div>

            <div class="col-12">
              <label for="">
                <span>Describe tu necesidad</span>
                <v-textarea
                  density="compact"
                  variant="solo-filled"
                  v-model="detail"
                  placeholder="..."
                  required
                  clearable
                ></v-textarea>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center py-3 mt-2">
              <span @click="doSubmit()">
                <CcButton
                  text="Solicitar Cotización"
                  :hideIcon="true"
                  :link="'javascript:;'"
                  template="Template-2"
                  :disabled="
                    names === '' ||
                    company === '' ||
                    email === '' ||
                    phone === '' ||
                    service === '' ||
                    quantity === ''
                  "
                />
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script setup>
import CcButton from "./shared/CcButton.vue";
import { ref } from "vue";
const emit = defineEmits(["close"]);

const names = ref("");
const email = ref("");
const company = ref("");
const phone = ref("");
const quantity = ref("");
const service = ref("");
const detail = ref("");

const doSubmit = () => {
  if (
    names.value &&
    company.value &&
    email.value &&
    phone.value &&
    service.value &&
    quantity.value
  ) {
    let text = "Hola Transportes Rafael, solicito una cotización:%0A %0A";
    text += "Nombres completos: " + names.value + "%0A";
    text += "Nombre de empresa: " + company.value + "%0A";
    text += "Correo electrónico: " + email.value + "%0A";
    text += "Teléfono: " + phone.value + " %0A";
    text += "Tipo de servicio: " + service.value + "%0A";
    text += "Cantidad de pasajeros: " + quantity.value + "%0A";
    text += "Detalle de servicio: " + detail.value + " ";
    const url = `https://wa.me/+51908898445?text=${text}`;

    window.open(url, "_blank");
    emit("close");
  }
};
</script>

<style lang="scss">
.trf-quotation {
  width: 100vw;
  height: 100vh;
  padding: 4rem 0 6rem;
  background-color: #000000d1;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  h2 {
    font-size: 50px;
    font-weight: 300;
    text-align: center;
    color: var(--bg-primary);
    margin: 0;
    b {
      font-weight: 600;
    }
  }

  form {
    width: 800px;
    height: 600px;
    background: url(../../../images/gallery/portrait-bg.jpg);
    background-size: cover;
    background-position: center;
    label {
      font-size: 12px;
      margin-bottom: -20px;
    }
    input,
    textarea {
      font-size: 12px;
      color: black;
    }
    button[type="submit"] {
      color: #005f96;
      text-transform: capitalize;
      font-weight: 800;
    }
  }

  .container {
    .divider {
      width: 300px;
      background: #fff;
      height: 3px;
      margin: 1rem auto;
    }
    > p {
      padding: 0.7rem 0 1.4rem;
      line-height: 1.2;
      text-align: center;
      font-size: 18px;
      width: 500px;
      color: #012d46;
      margin: 0 auto;
    }
  }
}

.form {
  &-wrapper {
    background-color: rgba(0, 95, 150, 0.9294117647);
    display: flex;
    width: 100%;
    height: 100%;
    flex-flow: column;
    justify-content: center;
    position: relative;
    img {
      width: 40px;
      position: absolute;
      top: -10px;
      right: -10px;
      cursor: pointer;
    }
  }

  &-inputs {
    div {
      label {
        display: flex;
        flex-flow: column;
        span {
          color: #fff;
          font-size: 14px;
          font-weight: 500;
          padding: 5px 0 3px;
        }
      }
    }
  }
}

@media (min-width: 360px) and (max-width: 800px) {
  .trf-quotation {
    width: 100vw;
    height: 100vh;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.8196078431);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    h2 {
      font-size: 27px;
      font-weight: 300;
      text-align: center;
      color: var(--bg-primary);
      margin: 0;
      b {
        font-weight: 600;
      }
    }
    form {
      margin: 0 !important;
      overflow: scroll;
      height: 100vh;
      .form-wrapper {
        height: 100%;
        min-height: 900px;
        width: 100% !important;
      }
    }
    .container {
      .divider {
        width: 150px;
        background: #fff;
        height: 2px;
        margin: 1rem auto;
      }
    }
  }
  .form {
    &-wrapper {
      background-color: rgba(0, 95, 150, 0.9294117647);
      display: flex;
      width: 100vw;
      height: 100vh;
      flex-flow: column;
      justify-content: center;
      position: relative;
      margin: 0;
    }
  }
}
</style>
