<template>
  <section class="trf-home">
    <img class="trf-home__logo" src="../../../images/logo.png" alt="" />
    <div class="trf-home__section">
      <div class="trf-home__section-box" @click="goToHome">
        <div>
          <div>
            <h3><span>Transporte</span> TURISTICO</h3>
            <div class="trf-home__section-action text-center pt-3">
              <CcButton text="Entrar al sitio" template="Template-4" />
            </div>
          </div>
        </div>
      </div>
      <div class="trf-home__section-divider"></div>
      <div class="trf-home__section-box" @click="goToHome">
        <div>
          <div>
            <h3><span>Servicio</span> CORPORATIVO</h3>
            <div class="trf-home__section-action text-center pt-3">
              <CcButton text="Entrar al sitio" template="Template-4" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useRouter } from "vue-router";
import CcButton from "../components/shared/CcButton.vue";

const router = useRouter();

const goToHome = () => {
  router.push({ path: "inicio" });
};
</script>

<style lang="scss">
.trf-home {
  width: 100vw;
  height: 100vh;

  &__logo {
    position: absolute;
    width: 180px;
    left: 20px;
    top: 20px;
    z-index: 99;
  }

  &__section {
    width: 100%;
    height: 100vh;
    display: flex;
    &-divider {
      height: 100%;
      width: 5px;
    }
    &-box {
      width: 50%;
      height: 100%;
      display: flex;
      position: relative;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      transition: all 0.5s;
      cursor: pointer;
      > div {
        background-color: #0000005c;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        h3 {
          span {
            font-size: 30px;
          }
          display: grid;
          text-align: center;
          font-size: 61px;
          font-weight: 600;
          color: #ffffff;
        }
      }
      &:nth-child(1) {
        background-image: url(../../../images/gallery/bg-1.jpg);
      }
      &:nth-child(3) {
        background-image: url(../../../images/gallery/bg-2.jpg);
      }
      &:hover {
        width: 90%;
        .trf-home__section-action {
          opacity: 1;
        }
      }
    }
    &-action {
      opacity: 0;
    }
  }
}
</style>
