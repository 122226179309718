<template>
  <section
    class="trf-component03 d-flex align-items-center position-relative overflow-hidden"
  >
    <div class="container">
      <div class="row h-100 trf-component03__wrap">
        <div
          class="col-6 h-100 bg-white h-100 trf-component03__content d-flex flex-column justify-content-center"
        >
          <div class="row trf-component03__items justify-content-center px-3">
            <div class="col-4 mb-3 text-center" v-for="item in items">
              <img src="../../../images/icons/icon-1.png" alt="" />
              <p>{{ item.text }}</p>
            </div>
          </div>
        </div>
        <div class="col-6 trf-component03__white">
          <div class="trf-component03__white-cxt">
            <h2 v-html="props.title"></h2>
            <p class="pt-4 mb-4 pb-2">
              {{ props.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import CcButton from "./shared/CcButton.vue";
import defineProps from "vue";

const props = defineProps({
  title: String,
  description: String,
  items: Array,
});
</script>

<style lang="scss">
.trf-component03 {
  background-color: var(--bg-background);
  min-height: 580px;
  > .container {
    min-height: 600px;
    height: 600px;
  }
  &__white {
    text-align: end;
    background: #005f96;
    background-image: url(../../../images/gallery/services.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    top: 0;
    padding: 0;
    display: flex;
    justify-content: end;

    &-cxt {
      padding: 5rem 3rem 5rem 6rem;
      h2,
      p,
      span {
        color: white;
      }

      h2 {
        font-size: 50px;
        line-height: 1;
        padding: 2rem rem 0;
      }
      p {
        font-size: 22px;
      }
      > p {
        line-height: 1.2;
        font-weight: 300;
      }
    }
  }

  &__items {
    background-color: white;
    img {
      width: 80px;
      margin-bottom: 1rem;
    }
    p {
      color: #012d46 !important;
      line-height: 1;
      font-size: 16px !important;
      text-align: center;
      font-weight: 500;
    }
  }

  &__content {
    h2,
    p,
    span {
      color: white;
    }

    h2 {
      font-size: 50px;
      line-height: 1;
      padding: 2rem rem 0;
    }
    p {
      font-size: 20px;
    }
    > p {
      line-height: 1.2;
      font-weight: 300;
    }

    li {
      width: max-content;
      padding: 1rem 1rem;
      border-radius: 15px;
      margin-bottom: 0.7rem;
      font-weight: 500;
      max-width: 320px;
      cursor: pointer;
      span {
        padding-right: 0.8rem;
      }
      &:hover {
        background-color: #00c2cb;
        p,
        span {
          color: #005f96;
        }
      }
      &.active {
        background-color: #00c2cb;
        p,
        span {
          color: #005f96;
        }
      }
    }
  }

  &__wrap {
    > div {
    }
  }
}
</style>
