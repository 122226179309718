<template>
  <section
    class="trf-component05 d-flex align-items-center position-relative overflow-hidden"
    :class="props.data?.Right && 'end'"
    :style="{
      backgroundImage:
        'url(' +
        'https://noseassapolio.online' +
        props.data?.Background?.data?.attributes?.url +
        ')',
    }"
  >
    <div
      class="container d-flex flex-column"
      :class="{ 'align-items-end text-end': props.data?.Right }"
    >
      <div
        class="row h-100 trf-component05__wrap"
        :class="{ 'justify-content-end': props.data?.Right }"
      >
        <h2>{{ props.data?.Name }}</h2>
        <p>{{ props.data?.Description }}</p>
        <CcButton
          template="Template-5"
          text="Solicitar cotización"
          hideIcon="true"
          :link="props.data?.Url+ '?text=Hola Transportes Rafael, estoy interesado en el servicio: ' + props.data?.Name"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import CcButton from "./shared/CcButton.vue";
import defineProps from "vue";

const props = defineProps({
  title: {
    type: String,
    default: "Alquiler de vehículos",
  },
  description: {
    type: String,
    default:
      "Mantenemos nuestra capacidad competitiva al más alto nivel profesional con el único objetivo de ser la empresa referente de nuestro sector. Creemos en la innovación y en la mejora continua.",
  },
  position: {
    type: String,
    default: "end",
  },
  image: String,
  items: Array,
  data: Object,
});
</script>

<style lang="scss">
.trf-component05 {
  width: 100%;
  height: 490px;
  border-top: 3px solid #fff;
  background-image: url(../../../images/gallery/minivan-toyota2.jpg);
  background-size: cover;
  background-position: center;
  &.start {
    background-image: url(../../../images/gallery/bg-3.jpg);
  }
  &__wrap {
    width: 650px;
    line-height: 1.2;
    h2 {
      line-height: 1.2;
      font-weight: 500;
      color: #fff;
      font-size: 45px;
      margin-bottom: 1rem;
    }
    p {
      color: #fff;
      margin: 0;
    }
  }
}

@media (min-width: 360px) and (max-width: 800px) {
  .trf-component05 {

    &__wrap {
      width: 100%;
      padding: 0 1rem;
      h2 {
        padding: 0;
        font-size: 30px;
      }
      > p {
        margin: 0;
      }
      p {
        padding: 0;
        font-size: 15px;
      }

      .our-clients {
        padding: 0;
        p {
        }
        img {
        }
      }
    }
  }
}

@media (min-width: 1990px) {
  .trf-component05 {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 3rem;
  }
}
</style>
