<template>
  <!-- <Component01 /> -->
  <section class="animate__animated animate__fadeInUp" v-if="pageData">
    <Component01
      template="Template-3"
      :title="pageData?.Title"
      :description="pageData?.Description"
    />
    <Vehicles :data="fleetData" />
    <!-- <Component02
      title="Nuestra filosofía gira en torno a la mayor comodidad y seguridad en nuestros servicios"
      description="Puedes depositar tu confianza en nosotros con la mayor tranquilidad: contamos con operadores capacitados y responsables, unidades confortables y seguras."
    /> -->
  </section>
</template>

<script setup>
import Navbar from "../components/Navbar.vue";
import Component01 from "../components/Component-01.vue";
import Vehicles from "../components/Vehicles.vue";
import Clients from "../components/Clients.vue";
import PageFooter from "../components/PageFooter.vue";
import CcMessage from "../components/shared/CcMessage.vue";
import { onMounted, ref } from "vue";

import { useQuery } from "villus";

const pageQuery = `query Pages {
    pages(filters: { PageName: { eq: "Flota" } }) {
        data {
            id
            attributes {
                PageName
                Slug
                Title
                Description
                MetaDescription
                MetaKeywords
                createdAt
                updatedAt
                publishedAt
                Components {
                  ... on ComponentPageSecFleet {
                        id
                        Name
                        Description
                        Vehicles {
                          id
                          Name,
                            Image {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                            ActiveImage {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                            Description
                            Seats
                            Capacity
                            Dimentions
                        }
                        Background {
                            data {
                                attributes {
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
`;

const pageData = ref(null);
const fleetData = ref(null);

onMounted(async () => {
  const { data } = await useQuery({
    query: pageQuery,
  });

  const pagData = data._rawValue.pages.data[0].attributes;
  pageData.value = pagData;
  fleetData.value = pagData.Components[0];
});
</script>

<style lang="scss">
.trf-services {
  height: 100px;
  width: 100%;
}
</style>
