<template>
  <section
    class="trf-clients d-flex align-items-center"
    v-bind:style="[showWhite && { background: 'white' }]"
  >
    <div class="container">
      <h2 v-html="props.data?.title"></h2>
      <div class="divider"></div>
      <p>
        {{ props.data?.description }}
      </p>
      <div class="trf-clients__logos d-flex justify-content-center">
        <template v-for="c in props.data?.clientes">
          <div
            v-if="c.Active"
            class="trf-clients__logo pb-5 d-flex justify-content-center align-items-center flex-column"
          >
            <img :src="c.logo" alt="" />
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script setup>
import { defineProps, onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";

const props = defineProps({
  data: Object,
});

const route = useRoute();
const router = useRouter();
const showWhite = ref(false);

onMounted(() => {
  showWhite.value = route.path === "/nosotros" || route.path === "/contactanos";
});

router.afterEach((to, from) => {
  showWhite.value = route.path === "/nosotros" || route.path === "/contactanos";
});
</script>

<style lang="scss">
.trf-clients {
  width: 100%;
  min-height: 550px;
  padding: 5rem 0;
  h2 {
    p {
      width: 100%;
      text-align: center;
      font-size: 50px;
      font-weight: 300;
      text-align: center;
      color: var(--bg-primary);
      margin: 0;
      padding: 0;
      strong {
        font-weight: 600;
      }
    }
  }
  p {
    padding: 0.7rem 0 1.4rem;
    line-height: 1.2;
    text-align: center;
    font-size: 18px;
    width: 550px;
    color: #012d46;
    margin: 0 auto;
  }
  .divider {
    width: 300px;
    background: #005f96;
    height: 3px;
    margin: 1rem auto;
  }
  &__logos {
    padding: 3rem 0 0;
    flex-flow: wrap;
  }
  &__logo {
    width: calc(100% / 4);
    img {
      margin: 0.5rem 0;
      max-width: 210px;
    }
  }
}

@media (min-width: 360px) and (max-width: 800px) {
  .trf-clients {
    h2 {
      p {
        font-size: 30px;
        strong {
        }
      }
    }
    p {
      width: auto;
    }
    .divider {
      width: 150px;
      background: #005f96;
      height: 2px;
      margin: 0.3rem auto;
    }
    &__logos {
      padding: 2rem 0 0;
      flex-flow: wrap;
    }
    &__logo {
      width: calc(100% / 2);
      img {
        margin: 0.1rem 0;
        width: calc(100vw / 3);
      }
    }
  }
}
</style>
