<template>
  <!-- <Component01 /> -->
  <section class="trf-home">
    <template v-if="servicesData">
      <Component04 :data="servicesData" />
    </template>

    <template v-if="secondServiceData">
      <Component05 :data="secondServiceData" />
    </template>

    <template v-if="secondServiceData_">
      <Component05 :data="secondServiceData_" />
    </template>
    <!-- <Component01
      title="Somos una empresa con <br />
        <b>Amplia experiencia</b> en de <br />
        transporte privado de personal"
    />
    <Component02
      title="Nos adaptamos a las necesidades particulares de su compañía"
      description="Porem ipsum dolor sit amet, consectetur orem ipsum dolor amet, consectetur adipiscing elit."
    /> -->
    <!-- <template v-if="fleetData">
      <Vehicles :data="fleetData" />
    </template> -->
  </section>
</template>

<script setup>
import Navbar from "../components/Navbar.vue";
import HeaderSlider from "../components/HeaderSlider.vue";
import Component04 from "../components/Component-04.vue";
import Component05 from "../components/Component-05.vue";
import Clients from "../components/Clients.vue";
import PageFooter from "../components/PageFooter.vue";
import CcMessage from "../components/shared/CcMessage.vue";
import Vehicles from "../components/Vehicles.vue";
import { onMounted, ref } from "vue";

import { useQuery } from "villus";

const homeQuery = `query Pages {
    pages(filters: { PageName: { eq: "Home" } }) {
        data {
            id
            attributes {
                PageName
                Slug
                MetaDescription
                MetaKeywords
                createdAt
                updatedAt
                publishedAt
                Components {
                    ... on ComponentPageSecSlider {
                        id
                        Name
                        Background {
                            data {
                                attributes {
                                    url
                                }
                            }
                        }
                        Slides {
                            Background {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                            Video {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                            Title
                            Description
                            Link
                        }
                    }
                    ... on ComponentPageSecClients {
                        id
                        Name
                        Description
                        Active
                        Clients {
                            id
                            Name
                            Active
                            Description
                            Logo {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                        }
                    }
                    ... on ComponentPageSecAboutus {
                        id
                        History
                        Mission
                        Vision
                        Portrait {
                            data {
                                attributes {
                                    url
                                }
                            }
                        }
                    }
                    ... on ComponentPageSecContact {
                        id
                        Cellphone
                        Email
                        Address
                        Maps
                    }
                    ... on ComponentPageSecFleet {
                        id
                        Name
                        Description
                        Vehicles {
                          id
                          Name,
                            Image {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                            ActiveImage {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                            Description
                            Seats
                            Capacity
                            Dimentions
                        }
                        Background {
                            data {
                                attributes {
                                    url
                                }
                            }
                        }
                    }
                    ... on ComponentPageSecServices {
                        Name
                        Services {
                            id
                            Name
                            Active
                            Description
                            Url
                            Right
                            Image {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                            Background {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
`;

const servicesData = ref(null);
const secondServiceData = ref(null);
const secondServiceData_ = ref(null);
const fleetData = ref(null);

onMounted(async () => {
  const { data } = await useQuery({
    query: homeQuery,
  });

  const pageData = data._rawValue.pages.data[0].attributes.Components;
  const firstService = pageData.find((c) => c.Name === "Servicios")
    ?.Services[0];
  const secondService = pageData.find((c) => c.Name === "Servicios")
    ?.Services[1];
  const secondService_ = pageData.find((c) => c.Name === "Servicios")
    ?.Services[2];
  fleetData.value = pageData[1];
  servicesData.value = firstService;
  secondServiceData.value = secondService;
  secondServiceData_.value = secondService_;
});
</script>

<style lang="scss">
.trf-home {
  height: auto;
  width: 100%;
  &__presentation {
    width: 100%;
    height: 100vh;
    min-height: 700px;
  }
  .trf-component01 {
    height: 100vh;
    min-height: 800px;
    justify-content: center;
    align-items: center;
    &__title {
      font-size: 60px;
    }
    &__actions {
      display: none;
    }
  }

  .trf-clients {
    background: #fff;
  }
}
</style>
