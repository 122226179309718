<template>
  <content v-if="refSlides">
    <section v-if="isHome" class="trf-home">
      <Navbar :data="headerData" template="Template-2" />
      <div class="trf-home__presentation position-relative">
        <HeaderSlider :slides="refSlides" />
      </div>
      <CcMessage />
    </section>

    <section v-if="!isHome" class="trf-services">
      <Navbar :data="headerData" template="Template-1" />
      <CcMessage />
    </section>
  </content>

  <router-view></router-view>

  <template v-if="clientsData">
    <Clients :data="clientsData" />
  </template>

  <template v-if="footerData">
    <PageFooter :data="footerData" />
  </template>

  <div
    class="page-loading"
    v-bind:class="
      outAnimated
        ? 'animate__animated animate__faster animate__fadeOutDown'
        : 'animate__animated animate__faster animate__fadeInUp'
    "
    v-if="isLoading"
  >
    <div class="wrap">
      <img src="../../../images/logo.png" alt="" />
      <h2>Cargando...</h2>
    </div>
  </div>
</template>

<script setup>
import Navbar from "../components/Navbar.vue";
import HeaderSlider from "../components/HeaderSlider.vue";
import Clients from "../components/Clients.vue";
import PageFooter from "../components/PageFooter.vue";
import CcMessage from "../components/shared/CcMessage.vue";
import { onMounted, computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import MarkdownIt from "markdown-it";
import { useQuery } from "villus";
import { onBeforeRouteUpdate } from "vue-router";
// import { useRecaptchaProvider } from 'vue-recaptcha'

const markdown = new MarkdownIt();
const isHome = ref(false);
const isLoading = ref(true);
const outAnimated = ref(false);

const AllPosts = `
{
    layouts(filters: null) {
        data {
            attributes {
                Title
                Components {
                    ... on ComponentPageSecFooter {
                        Description
                        Logo {
                            Logo {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                        }
                        Facebook
                        Instagram
                        Twitter
                        Cellphone
                        Email
                        Maps
                    }
                    ... on ComponentPageSecHeader {
                        Logo {
                            data {
                                attributes {
                                    url
                                }
                            }
                        }
                        Links {
                            Link
                            Name,
                            Active
                        }
                    }
                    ... on ComponentPageSecClients {
                        id
                        Description
                        Active
                        Name
                        Clients {
                            Name
                            Active
                            Description
                            Logo {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

`;

const homeQuery = `query Pages {
    pages(filters: { PageName: { eq: "Home" } }) {
        data {
            id
            attributes {
                PageName
                Slug
                MetaDescription
                MetaKeywords
                createdAt
                updatedAt
                publishedAt
                Components {
                    ... on ComponentPageSecSlider {
                        id
                        Name
                        Background {
                            data {
                                attributes {
                                    url
                                }
                            }
                        }
                        Slides {
                            Background {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                            Video {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                            VideoBackground {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                            Title
                            Description
                            Link
                        }
                    }
                    ... on ComponentPageSecClients {
                        id
                        Name
                        Description
                        Active
                        Clients {
                            id
                            Name
                            Active
                            Description
                            Logo {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                        }
                    }
                    ... on ComponentPageSecAboutus {
                        id
                        History
                        Mission
                        Vision
                        Portrait {
                            data {
                                attributes {
                                    url
                                }
                            }
                        }
                    }
                    ... on ComponentPageSecContact {
                        id
                        Cellphone
                        Email
                        Address
                        Maps
                    }
                    ... on ComponentPageSecFleet {
                        id
                        Name
                        Description
                        Vehicles {
                          id
                          Name,
                            Image {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                            ActiveImage {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                            Description
                            Seats
                            Capacity
                            Dimentions
                        }
                        Background {
                            data {
                                attributes {
                                    url
                                }
                            }
                        }
                    }
                    ... on ComponentPageSecServices {
                        Name
                        Services {
                            id
                            Name
                            Active
                            Description
                            Url
                            Right
                            Image {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                            Background {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
`;

const route = useRoute();
const router = useRouter();
const headerData = ref(null);
const footerData = ref(null);
const clientsData = ref(null);
const firstLoad = ref(true);
const host = "https://noseassapolio.online";
const refSlides = ref();

router.afterEach((to, from) => {
  isHome.value = route.path === "/" || route.path === "/inicio";
});

onBeforeRouteUpdate((to, from) => {
  isLoading.value = true;
  toggleAnimate();
});

function toggleAnimate() {
  const t = firstLoad.value ? 4500 : 2000;
  setTimeout(() => {
    outAnimated.value = true;
    firstLoad.value = false;
    setTimeout(() => {
      window.scrollTo(0, 0);
      outAnimated.value = false;
      isLoading.value = false;
    }, 500);
  }, t);
}

onMounted(async () => {
  toggleAnimate();

  isHome.value = route.path === "/" || route.path === "/inicio";

  const { data } = await useQuery({
    query: AllPosts,
  });

  const { data: homeData } = await useQuery({
    query: homeQuery,
  });

  refSlides.value = homeData._rawValue.pages.data[0].attributes.Components[2];

  const layout = data._rawValue.layouts.data[0].attributes.Components;
  const header = layout[1];
  const footer = layout[0];
  const clients = layout[2];

  headerData.value = {
    logo: `${host}${header.Logo.data.attributes.url}`,
    menu: header.Links,
  };

  footerData.value = {
    logo: `${host}${footer.Logo.Logo.data.attributes.url}`,
    maps: footer.Maps,
    twitter: footer.Twitter,
    instagram: footer.Instagram,
    facebook: footer.Facebook,
    email: footer.Email,
    description: footer.Description,
    cellphone: footer.Cellphone,
    menu: header.Links,
  };

  clientsData.value = {
    title: markdown.render(clients.Name),
    description: clients.Description,
    clientes: clients.Clients.map((c) => ({
      name: c.Name,
      Active: c.Active,
      logo: `${host}${c.Logo.data.attributes.url}`,
    })),
  };
});
</script>

<style lang="scss">
.trf-home {
  height: auto;
  width: 100%;
  &__presentation {
    width: 100%;
    height: 100vh;
    min-height: 700px;
  }
  .trf-component01 {
    height: 100vh;
    min-height: 800px;
    justify-content: center;
    align-items: center;
    &__title {
      font-size: 60px;
    }
    &__actions {
      display: none;
    }
  }

  .trf-clients {
    background: #fff;
  }
}

.page-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fffffff5;
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 140px;
  }
  h2 {
    text-transform: uppercase;
    font-size: 0.8rem;
    text-align: center;
    padding: 1rem;
    font-weight: 700;
    color: #005f96;
  }
}

@media (min-width: 360px) and (max-width: 800px) {
  .trf-home {
    &__presentation {
      height: 600px;
    }
    .trf-component01 {
      height: 600px;
      &__title {
      }
      &__actions {
      }
    }

    .trf-clients {
    }
  }
}
</style>
