<template>
  <div :class="props.template">
    <section class="trf-component01 d-flex">
      <div class="container my-5 pt-4 pb-3">
        <h2
          class="trf-component01__title w-100"
          v-html="parsedMarkdown(props.title)"
        ></h2>
        <p
          class="trf-component01__description w-100"
          v-if="props.description"
          v-html="parsedMarkdown(props.description)"
        ></p>
        <div class="trf-component01__divider"></div>

        <div
          class="trf-component01__items d-flex justify-content-center gap-4"
          v-if="withItems === true"
        >
          <div class="trf-component01__item" v-for="item of items">
            <div class="trf-component01__item-portrait h-100">
              <div
                class="d-flex h-100 flex-column justify-content-between px-3 py-4 w-100"
              >
                <h2 class="text-white">{{ item.label }}</h2>
                <p class="text-white">{{ item.title }}</p>
              </div>
            </div>
            <div class="trf-component01__item-content">
              <div class="d-flex h-100 flex-column px-3 py-4 w-100">
                <h2 class="d-flex flex-column">
                  <span>Servicio:</span>
                  {{ item.label }}
                </h2>
                <p>{{ item.description }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="row trf-component01__actions" v-if="withItems === true">
          <div class="col-12 text-center pt-5">
            <CcButton text="Cotizar servicio" template="Template-2" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import CcButton from "./shared/CcButton.vue";
import MarkdownIt from "markdown-it";

const markdown = new MarkdownIt();

const parsedMarkdown = (val) => markdown.render(val);

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  description: {
    type: String,
    default: "",
  },
  template: String,
  items: Array,
  withItems: {
    type: Boolean,
    default: true,
  },
});

const items = [
  {
    label: "Transporte de personal",
    title:
      "Porem ipsum dolor sit amet, consectetur orem ipsum dolor amet, consectetur adipiscing elit.",
    description:
      "Nuestro servicio de transporte de personal no sólo se centra en la eficiencia y puntualidad. También contamos con unidades acogedoras que mantendrán contento a tu personal. Además de que tendrás la posibilidad de diseñar las rutas más amables con tus colaboradores.",
  },
  {
    label: "Alquiler de vehículos",
    title:
      "Porem ipsum dolor sit amet, consectetur orem ipsum dolor amet, consectetur adipiscing elit.",
    description:
      "Nuestro servicio de transporte de personal no sólo se centra en la eficiencia y puntualidad. También contamos con unidades acogedoras que mantendrán contento a tu personal. Además de que tendrás la posibilidad de diseñar las rutas más amables con tus colaboradores.",
  },
  {
    label: "Transporte particular",
    title:
      "Porem ipsum dolor sit amet, consectetur orem ipsum dolor amet, consectetur adipiscing elit.",
    description:
      "Nuestro servicio de transporte de personal no sólo se centra en la eficiencia y puntualidad. También contamos con unidades acogedoras que mantendrán contento a tu personal. Además de que tendrás la posibilidad de diseñar las rutas más amables con tus colaboradores.",
  },
];
</script>

<style lang="scss">
.trf-component01 {
  // background-color: var(--bg-background);
  &__title {
    padding: 0 0 2rem 0;
    p {
      font-size: 50px;
      font-weight: 300;
      color: var(--bg-primary);
      line-height: 1;
      padding: 0;
      max-width: 900px;
      margin: 0;
      strong,
      b {
        font-weight: 600;
      }
    }
  }

  &__description {
    font-size: 20px;
    line-height: 1.2;
    color: #012d46;
    // padding-bottom: 3rem;
    max-width: 900px;
  }

  &__divider {
    width: 40%;
    height: 2px;
    margin-bottom: 3.5rem;
    background-color: var(--bg-primary);
  }

  &__items {
  }

  &__item {
    width: 270px;
    height: 300px;
    position: relative;
    border: 2px solid #005f96e6;
    h2 {
      font-size: 20px;
      line-height: 1;
      padding-right: 1rem;
    }

    p {
      font-size: 16px;
      line-height: 1;
      text-align: end;
      font-weight: 300;
    }

    &-portrait {
      background-image: url(../../../images/gallery/1.jpg);
      background-size: cover;
      background-position: center;
      div {
        background-color: #005f96e6;
      }
    }

    &-content {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      z-index: 1;
      background-image: url(../../../images/gallery/1.jpg);
      background-size: cover;
      background-position: center;
      div {
        background-color: #ffffffe6;
        h2,
        p {
          color: #012d46;
          font-weight: 500;
        }
        h2 {
          span {
            font-size: 14px;
            font-weight: 800;
          }
        }
        p {
          text-align: start;
          margin-top: 13px;
          font-weight: 400;
        }
      }
    }

    &:hover {
      .trf-component01__item-content {
        display: block;
      }

      .trf-component01__item-portrait {
        display: none;
      }
    }
  }
}

.Template-2 {
  .trf-component01 {
    &__title {
      padding-bottom: 0.6rem;
    }
    &__divider {
      display: none;
    }
  }
}

.Template-3 {
  .trf-component01 {
    // min-height: 450px !important;
    // height: 450px !important;
  }
}

.Template-3 {
  .trf-component01 {
    &__title {
      padding-bottom: 0.6rem;
    }
    &__divider,
    &__items,
    &__actions {
      display: none !important;
    }
  }
}

.Template-4 {
  .trf-component01 {
    background-color: transparent;
    height: auto;
    &__title {
      padding-bottom: 0;
      border-bottom: none;
      margin-bottom: 0;
      display: flex;
      flex-flow: column;
      width: max-content;
    }
    &__divider,
    &__items,
    &__actions {
      display: none !important;
    }
    &__content {
      p {
        font-size: 18px;
        line-height: 1.1;
      }
      ul {
        li {
          p {
            font-size: 20px;
            font-weight: 600;
            color: #a8a8a8;
            &:hover {
              color: #005f96;
              font-weight: 700;
              cursor: pointer;
            }
          }
          &.active {
            p {
              color: #005f96;
              font-weight: 700;
            }
          }
        }
      }

      .col-6 {
        p {
          color: #012d46;
        }
      }
    }
  }
}

@media (min-width: 360px) and (max-width: 800px)  {
  .trf-component01__title p {
    font-size: 30px;
  }

  .trf-component01__description {
    font-size: 18px;
  }

}
</style>
