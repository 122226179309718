<template>
  <a class="cc-message" v-on:click="showQuotation = true" :class="props.template" href="javascript:;">
    {{ props.text }}
    <img src="../../../../images/icons/message.svg" alt="" />
  </a>

  <Quotation @close="doClose()" v-if="showQuotation" />
</template>

<script setup>
import {defineProps, ref} from "vue";
import Quotation from "../Quotation.vue";

const showQuotation = ref(false);

const props = defineProps({
  template: String,
  text: String,
});

const doClose = () => {
  showQuotation.value = false
}
</script>

<style lang="scss">
.cc-message {
  position: fixed;
  right: 0;
  top: calc(100vh - 80%);
  z-index: 99999;
  cursor: pointer;
  img {
    width: 40px;
  }
}
</style>
