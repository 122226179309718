<template>
  <section
    class="trf-component02 position-relative overflow-hidden"
  >
    <div class="container h-100">
      <div class="row trf-component02__wrap">
        <div
          class="col-6 trf-component02__content px-4 pe-5 d-flex flex-column justify-content-center"
        >
          <h2>{{ props.title }}</h2>
          <p class="pt-4 mb-4 pb-2">
            {{ props.description }}
          </p>

          <ul class="list-unstyled">
            <li class="active">
              <p class="d-flex justify-content-between lh-1 m-0">
                <span>1.</span> Transporte de personal obrero
              </p>
            </li>
            <li>
              <p class="d-flex justify-content-between lh-1 m-0">
                <span>2.</span> Transporte de personal empleado exclusivo
              </p>
            </li>
            <li>
              <p class="d-flex justify-content-between lh-1 m-0">
                <span>3.</span> Transporte de personal para turismo nacional
              </p>
            </li>
          </ul>
        </div>
        <div class="col-6 trf-component02__white">
          <div class="trf-component02__white-cxt">
            <div class="active-image"></div>
            <div class="px-5 pt-5 pb-5 position-relative">
              <span class="active-text">1.</span>
              <h3>
                Hacemos uso de la tecnología más avanzada para garantizar
                seguridad.
              </h3>

              <p>
                Somos un equipo orientado al cliente con el único objetivo de
                ofrecer la más completa y cualificada atención. Formado por
                distintas generaciones con diferentes perspectivas y
                experiencias en el sector. Por nuestra experiencia podemos
                afirmar que ofrecemos el mejor servicio de transporte de
                empleados.
              </p>

              <div>
                <CcButton text="Cotizar servicio" template="Template-3" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import CcButton from "./shared/CcButton.vue";
import defineProps from "vue";

const props = defineProps({
  title: String,
  description: String,
});
</script>

<style lang="scss">
.trf-component02 {
  background-color: var(--bg-background);
  > .container {
    height: 600px;
  }
  &__white {
    background-color: #f5f5f5;
    top: 0;
    padding: 0;
    &-cxt {
      .active-image {
        width: 100%;
        height: 400px;
        background: url(../../../images/gallery/1.jpg);
        background-size: cover;
        background-position: center;
      }
      div {
        position: relative;
        z-index: 9;
      }
      span {
        position: absolute;
        font-size: 22rem;
        top: -7px;
        left: 25px;
        line-height: 1;
        font-weight: 800;
        color: white;
      }
      h3,
      p {
        color: #012d46;
        position: relative;
        z-index: 9;
      }
      h3 {
        font-size: 25px;
        font-weight: 500;
        padding-right: 1rem;
      }
      p {
        padding: 0.7rem 0;
        line-height: 1.2;
        font-size: 16px;
      }
    }
  }

  &__content {
    background-color: #005f96;

    h2,
    p,
    span {
      color: white;
    }

    h2 {
      font-size: 38px;
      line-height: 1;
      padding: 2rem rem 0;
    }
    p {
      font-size: 18px;
    }
    > p {
      line-height: 1.2;
      font-weight: 300;
    }

    li {
      width: max-content;
      padding: 1rem 1rem;
      border-radius: 15px;
      margin-bottom: 0.7rem;
      font-weight: 500;
      max-width: 320px;
      cursor: pointer;
      span {
        padding-right: 0.8rem;
      }
      &:hover {
        background-color: #00c2cb;
        p,
        span {
          color: #005f96;
        }
      }
      &.active {
        background-color: #00c2cb;
        p,
        span {
          color: #005f96;
        }
      }
    }
  }

  &__wrap {
    > div {
    }
  }
}
</style>
