<template>
  <section class="trf-contact">
    <div class="container">
      <div class="contact-page animate__animated animate__fadeInUp">
        <div class="col-12 mb-3">
          <h2>
            <p><strong>Libro de Reclamaciones Virtual</strong></p>
          </h2>
          <div class="divider"></div>
          <p>
            Una vez enviado este formulario, el NÚMERO DE HOJA DE RECLAMACION
            será generado de forma automática. Usted podrá verlo en PDF al finalizar.
          </p>
        </div>
        <div class="py-2 d-none d-md-block"></div>
        <div class="contact-form m-auto mt-5">
          <div class="col-12">
            <h3 class="text-center d-flex flex-column">
              EMPRESA DE TRANSPORTES RAFAEL
              <span class="h5 fw-lighter">
                <strong>RUC:</strong>
                20483995254
              </span>
            </h3>
            <p class="subtitle d-flex justify-content-between">
              Hoja de reclamación <span>{{ parsedToday }}</span>
            </p>
          </div>
          <div class="col-12 pt-3 pt-md-5">
            <h4 class="mb-4"><span>1.</span> Identificación del Consumidor:</h4>
            <form class="row justify-content-center">
              <div class="col-12 col-md-4">
                <label class="w-100">
                  Tipo Documento (*)
                  <v-select
                    density="compact"
                    variant="solo-filled"
                    v-model="contactForm.documentType"
                    :items="['DNI', 'C.E', 'RUC', 'Pasaporte']"
                    required
                    clearable
                  ></v-select>
                </label>
              </div>

              <div class="col-12 col-md-4">
                <label class="w-100">
                  Nro Documento (*)
                  <v-text-field
                    density="compact"
                    variant="solo-filled"
                    v-model="contactForm.document"
                    required
                    clearable
                  ></v-text-field>
                </label>
              </div>

              <div class="col-12 col-md-4">
                <label class="w-100">
                  Seleccione su género (*)
                  <v-radio-group required v-model="contactForm.gender" inline>
                    <v-radio label="Masculino" value="masculino"></v-radio>
                    <v-radio label="Femenino" value="femenino"></v-radio>
                  </v-radio-group>
                </label>
              </div>

              <div class="col-12 col-md-4">
                <label class="w-100">
                  Nombres Completos (*)
                  <v-text-field
                    density="compact"
                    variant="solo-filled"
                    clearable
                    required
                    v-model="contactForm.names"
                  ></v-text-field>
                </label>
              </div>

              <div class="col-12 col-md-4">
                <label class="w-100">
                  Apellido Paterno (*)
                  <v-text-field
                    density="compact"
                    variant="solo-filled"
                    clearable
                    required
                    v-model="contactForm.firstLastName"
                  ></v-text-field>
                </label>
              </div>

              <div class="col-12 col-md-4">
                <label class="w-100">
                  Apellido Materno (*)
                  <v-text-field
                    density="compact"
                    variant="solo-filled"
                    clearable
                    required
                    v-model="contactForm.secondLastName"
                  ></v-text-field>
                </label>
              </div>

              <div class="col-12">
                <div class="row">
                  <div class="col-12 col-md-4">
                    <label class="w-100">
                      Dirección (*)
                      <v-text-field
                        density="compact"
                        variant="solo-filled"
                        clearable
                        required
                        v-model="contactForm.address"
                      ></v-text-field>
                    </label>
                  </div>

                  <div class="col-12 col-md-4">
                    <label class="w-100">
                      Teléfono (*)
                      <v-text-field
                        density="compact"
                        variant="solo-filled"
                        clearable
                        required
                        v-model="contactForm.phone"
                      ></v-text-field>
                    </label>
                  </div>

                  <div class="col-12 col-md-4">
                    <label class="w-100">
                      Correo electrónico (*)
                      <v-text-field
                        density="compact"
                        variant="solo-filled"
                        clearable
                        required
                        v-model="contactForm.mail"
                      ></v-text-field>
                    </label>
                  </div>

                  <div class="col-12 col-md-4">
                    <label class="w-100">
                      Nombre del Padre o Madre:
                      <v-text-field
                        density="compact"
                        variant="solo-filled"
                        placeholder="En caso de ser menor de edad"
                        clearable
                        v-model="contactForm.dad"
                      ></v-text-field>
                    </label>
                  </div>

                  <div class="col-12 col-md-4">
                    <label class="w-100">
                      Fecha de nacimiento (*)
                      <v-date-input
                        density="compact"
                        variant="solo-filled"
                        v-model="contactForm.bornDate"
                        clearable
                      ></v-date-input>
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="col-12 pt-3 pt-md-5">
            <h4 class="mb-4"><span>2.</span>Identificación de Solicitud:</h4>
            <form class="row justify-content-center">
              <div class="col-12">
                <label class="w-100">
                  <v-radio-group v-model="contactForm.type" inline>
                    <v-radio label="Reclamo" value="Reclamo"></v-radio>
                    <v-radio label="Queja" value="Queja"></v-radio>
                  </v-radio-group>
                </label>

                <div class="claims-helper w-100">
                  <p class="d-flex flex-column lh-1 gap-1">
                    <span>Reclamo: </span>
                    Disconformidad relacionada a los productos o servicios.
                  </p>
                  <p class="d-flex flex-column lh-1 gap-1 m-0">
                    <span>Queja: </span>
                    Malestar o descontento con respecto a la atención al
                    público. (Disconformidad no relacionada a producto o
                    servicio.)
                  </p>
                </div>
              </div>

              <div class="col-12 col-md-4">
                <label class="w-100">
                  Seleccione el Motivo (*)
                  <v-select
                    density="compact"
                    variant="solo-filled"
                    v-model="contactForm.motive"
                    required
                    :items="[
                      'Impuntualidad en la salida o llegada',
                      'Mantenimiento del vehiculo',
                      'Problemas con el equipaje',
                      'Atención de servicio abordo',
                      'Otros',
                    ]"
                    clearable
                  ></v-select>
                </label>
              </div>

              <div class="col-12 col-md-4">
                <label class="w-100">
                  Fecha de Servicio (*)
                  <v-date-input
                    density="compact"
                    variant="solo-filled"
                    v-model="contactForm.date"
                    required
                    clearable
                  ></v-date-input>
                </label>
              </div>

              <div class="col-12 col-md-4">
                <label class="w-100">
                  Costo del Servicio
                  <v-text-field
                    density="compact"
                    v-model="contactForm.amount"
                    variant="solo-filled"
                    clearable
                  ></v-text-field>
                </label>
              </div>

              <div class="col-12">
                <label class="w-100">
                  Resumen de lo Acontecido (*)
                  <v-textarea
                    density="compact"
                    variant="solo-filled"
                    v-model="contactForm.resume"
                    required
                    clearable
                  ></v-textarea>
                </label>
              </div>

              <div class="col-12" v-if="contactForm.type === 'Reclamo'">
                <label class="w-100">
                  Pedido y/o Monto reclamado
                  <v-text-field
                    density="compact"
                    variant="solo-filled"
                    v-model="contactForm.amountClain"
                    clearable
                  ></v-text-field>
                </label>
              </div>

              <div class="col-12 mt-4 text-end d-flex justify-content-end">
                <div class="d-flex">
                  <vueRecaptcha
                    sitekey="6Le__SgqAAAAAAuaXL6Em6hmtmf5Z57BzOPBUYPo"
                    size="normal"
                    theme="light"
                    @verify="recaptchaVerified"
                  >
                  </vueRecaptcha>
                </div>
              </div>

              <div class="col-12 mt-4 text-end">
                <v-btn
                  type="submit"
                  :loading="loading"
                  v-bind:disabled="!isValid"
                  @click="doSubmit($event)"
                >
                  Enviar reclamo y/o queja
                </v-btn>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import vueRecaptcha from "vue3-recaptcha2";
import { computed, onMounted, ref } from "vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const defaultFg = {
  documentType: "DNI",
  document: "",
  gender: "",
  names: "",
  firstLastName: "",
  secondLastName: "",
  address: "",
  phone: "",
  mail: "",
  dad: "",
  bornDate: new Date(),
  type: "Reclamo",
  motive: "Otros",
  date: new Date(),
  amount: "",
  resume: "",
  amountClain: "",
};
const tokenVerify = ref("");
const contactForm = ref({
  ...defaultFg,
});
const loading = ref(false);
const nDate = new Date();
const parsedToday = `${nDate.getDate()}/${
  nDate.getMonth() + 1
}/${nDate.getFullYear()}`;

const isValid = computed(() => {
  if (tokenVerify.value) {
    const requiredInputs = [
      contactForm.value.documentType,
      contactForm.value.document,
      contactForm.value.gender,
      contactForm.value.names,
      contactForm.value.firstLastName,
      contactForm.value.secondLastName,
      contactForm.value.address,
      contactForm.value.phone,
      contactForm.value.mail,
      contactForm.value.type,
      contactForm.value.motive,
      contactForm.value.bornDate,
      contactForm.value.date,
      contactForm.value.resume,
    ];

    return !requiredInputs.filter((f) => f === "" || f === null).length;
  } else return false;
});

const recaptchaVerified = ($event) => {
  tokenVerify.value = $event;
};

const doSubmit = async (event) => {
  event.preventDefault();
  loading.value = true;
  if (isValid.value) {
    try {
      const postBody = {
        documentType: contactForm.value.documentType,
        document: contactForm.value.document,
        gender: contactForm.value.gender,
        names: contactForm.value.names,
        firstLastName: contactForm.value.firstLastName,
        secondLastName: contactForm.value.secondLastName,
        address: contactForm.value.address,
        phone: contactForm.value.phone,
        mail: contactForm.value.mail,
        type: contactForm.value.type,
        dad: contactForm.value.dad,
        motive: contactForm.value.motive,
        bornDate: contactForm.value.bornDate,
        date: contactForm.value.date,
        resume: contactForm.value.resume,
        amount: contactForm.value.amount,
        amountClain: contactForm.value.amountClain,
      };

      const response = await fetch("https://transporterafael.com/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postBody),
      });
      const res  =  await response.json();
      const linkSource = `data:application/pdf;base64,${res.pdf}`;

      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = 'Libro de Reclamaciones Virtual.pdf';
      downloadLink.click();   

      loading.value = false;
      contactForm.value = defaultFg;
      //
      toast.success("Formulario enviado correctamente.");
    } catch (error) {
      console.error("Fetch error:", error);
    }
  }
};
</script>

<style lang="scss">
.claims-helper {
  background: rgba(255, 255, 255, 0.15);
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1.4rem;
  font-size: 12px;
  margin-top: -14px;
  span {
    font-weight: 600;
  }
}
.contact-form {
  width: 800px;
  background: #005f96;
  padding: 1.5rem 3rem;
  color: #fff;
  @media (min-width: 360px) and (max-width: 800px) {
    width: 100%;
    padding: 1.5rem;
  }
  .subtitle {
    border-top: 1px solid;
    border-bottom: 1px solid;
    padding: 0.2rem 0;
    margin-top: 1rem;
  }
  h4 {
    @media (min-width: 360px) and (max-width: 800px) {
      font-size: 1rem;
    }
  }
  form {
    padding: 1.5rem 0;
    label {
      font-size: 13px;
    }
    input,
    textarea {
      font-size: 12px;
      color: black;
    }
  }
  button[type="submit"] {
    color: #005f96;
    text-transform: capitalize;
    font-weight: 800;
  }
}
.gg-map {
  width: 100%;
  height: 300px;
}
.box-recaptcha {
  display: flex;
  transform: scale(0.94);
  justify-content: center;
  padding-top: 14px;
}
.trf-contact {
  width: 100%;
  .trf-clients {
    background: var(--bg-background);
  }
}

.submt {
  background-color: #00c2cb;
  text-decoration: none;
  color: white;
  padding: 12px 15px 12px 18px;
  border-radius: 30px;
  font-weight: 500;
  font-size: 18px;
  width: 167px;
  font-size: 16px;
  text-align: center;
  padding: 12px 16px !important;
  margin: 1rem auto;
  margin-bottom: 0;
  border: none;
  &.disabled {
    background: gray !important;
    opacity: 0.4 !important;
  }
}

@media (min-width: 360px) and (max-width: 800px) {
  .contact-page {
    padding: 3rem 0;
  }
  .contact-page > div > h2 p {
    font-size: 30px;
  }
  .contact-page > div > p {
    font-size: 18px;
    width: auto;
  }
  .contact-page .divider {
    width: 150px;
    background: #005f96;
    height: 2px;
    margin: 0.3rem auto;
  }
  .contact-page__form {
    width: 100%;
  }

  .contact-page__data p img {
    width: 30px;
  }
}

.trf-clients {
  background: #fff;
}
</style>
