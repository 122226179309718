<template>
  <section class="trf-clients__page" v-if="clientsData">
    <Component01
      template="Template-2"
      :title="clientsData.Title"
      :with-items="false"
    />
    <section class="clients-slide">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="row pt-4 pb-1">
              <div class="col-12 col-md-4 slide-image">
                <img
                  class="img-fluid"
                  src="../../../images/gallery/client-1.jpg"
                  alt=""
                />
                <p>Petro perú</p>
              </div>
              <div
                class="col-12 col-md-8 slide-content d-flex flex-column justify-content-center"
              >
                <h2 class="pb-md-4">
                  “Lorem Ipsum es simplemente el texto de relleno de las
                  imprentas y archivos de texto. Lorem Ipsum ha sido el texto de
                  relleno estándar de las industrias desde el”
                </h2>

                <div class="company mt-3 mt-md-5 pb-5 pb-md-0">
                  <p>Jhon doe - Gerente de Recursos humanos</p>
                  <img src="../../../images/logos/1.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script setup>
import Navbar from "../components/Navbar.vue";
import Component01 from "../components/Component-01.vue";
import Clients from "../components/Clients.vue";
import PageFooter from "../components/PageFooter.vue";
import CcMessage from "../components/shared/CcMessage.vue";

import { onMounted, ref } from "vue";

import { useQuery } from "villus";

const pageQuery = `query Pages {
    pages(filters: { PageName: { eq: "Clientes" } }) {
        data {
            id
            attributes {
                PageName
                Slug
                Title
                Description
                MetaDescription
                MetaKeywords
                createdAt
                updatedAt
                publishedAt
                Components {
                    ... on ComponentPageSecClients {
                        id
                        Name
                        Description
                        Active
                        Clients {
                            id
                            Name
                            Active
                            Description
                            Logo {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                        }
                    }

                    ... on ComponentPageSecContact {
                        id
                        Cellphone
                        Email
                        Address
                        Maps
                    }
                }
            }
        }
    }
}
`;

const clientsData = ref(null);

onMounted(async () => {
  const { data } = await useQuery({
    query: pageQuery,
  });

  const pageData = data._rawValue.pages.data[0].attributes;
  clientsData.value = pageData;
});
</script>

<style lang="scss">
.trf-clients__page {
  width: 100%;
  .slide-image {
    p {
      font-weight: 600;
    }
  }
  .slide-content {
    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      max-width: 600px;
    }
    .company {
      p {
        font-size: 1.3rem;
        color: #012d46;
      }
      img {
        width: 170px;
      }
    }
  }
}

.clients-slide {
  width: 100%;
  background-color: var(--bg-background);
}
</style>
