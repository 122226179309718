<template>
  <!-- <Component01 /> -->
  <section
    class="trf-aboutus animate__animated animate__fadeInUp"
    v-if="pageData?.Title"
  >
    <Component01
      template="Template-4"
      :title="pageData.Title"
      :with-items="false"
    />
    <section class="container pb-5">
      <div class="row">
        <div class="col-12 text-center">
          <img
            :src="
              'https://noseassapolio.online' +
              historyData.Portrait.data.attributes.url
            "
            class="w-100 portrait"
            alt="Banner About Us"
          />
        </div>
      </div>

      <div
        class="row justify-content-center align-items-center mt-4"
        v-if="historyData.History"
      >
        <div class="col-12 history about-us-items pt-2 pt-md-5 px-3 px-md-5">
          <div class="row">
            <div class="col-12">
              <h2>Nuestra Historia</h2>
            </div>
            <div
              class="col-12"
              v-html="parsedMarkdown(historyData.History)"
            ></div>
          </div>
        </div>
      </div>

      <div class="row border-none about-us-items px-3 px-md-5">
        <div class="col-12 col-md-6 m-0 p-0 border-none">
          <div
            class="row justify-content-center align-items-center border-none"
            v-if="historyData.Mission"
          >
            <div class="col-12 border-none m-0 p-0">
              <h2 class="text-center">Misión</h2>
            </div>
            <div class="col-12 border-none m-0 p-0 text-center">
              <p class="px-3">
                {{ historyData.Mission }}
              </p>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 m-0 p-0 border-none">
          <div
            class="row justify-content-center align-items-center border-none"
            v-if="historyData.Vision"
          >
            <div class="col-12 border-none m-0 p-0 pt-5 pt-md-0">
              <h2 class="text-center">Visión</h2>
            </div>
            <div class="col-12 border-none m-0 p-0 text-center">
              <p class="px-3">
                {{ historyData.Vision }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <Component02
      title="Nos adaptamos a las necesidades particulares de su compañía"
      description="Puedes depositar tu confianza en nosotros con la mayor tranquilidad: contamos con operadores capacitados y responsables, unidades confortables y seguras."
    /> -->
  </section>
</template>

<script setup>
import Navbar from "../components/Navbar.vue";
import Component01 from "../components/Component-01.vue";
import Clients from "../components/Clients.vue";
import PageFooter from "../components/PageFooter.vue";
import CcMessage from "../components/shared/CcMessage.vue";
import { onMounted, ref } from "vue";

import { useQuery } from "villus";
import MarkdownIt from "markdown-it";

const markdown = new MarkdownIt();
const parsedMarkdown = (val) => markdown.render(val);

const pageQuery = `query Pages {
    pages(filters: { PageName: { eq: "Nosotros" } }) {
        data {
            id
            attributes {
                PageName
                Slug
                Title
                Description
                MetaDescription
                MetaKeywords
                createdAt
                updatedAt
                publishedAt
                Components {
                    ... on ComponentPageSecAboutus {
                        id
                        History
                        Mission
                        Vision
                        Portrait {
                            data {
                                attributes {
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
`;

const pageData = ref(null);
const historyData = ref(null);

onMounted(async () => {
  const { data } = await useQuery({
    query: pageQuery,
  });

  pageData.value = data._rawValue.pages.data[0].attributes;
  historyData.value = pageData.value.Components[0];
});
const items = [
  {
    text: "Nuestra Historia",
    description: "",
  },
  {
    text: "Visión",
    description: "",
  },
  {
    text: "Misión",
    description: "",
  },
];
</script>

<style lang="scss">
.trf-services {
  height: 100px;
  width: 100%;
}

.about-us-items {
  padding: 1.5rem 4rem;
  &.history {
    background-image: url(../../../images/shield.png);
    height: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    > div {
      height: auto;
      display: block;
      padding-bottom: 1.7rem;
      h2 {
        margin-bottom: 2rem;
        text-align: center;
        width: 100%;
        max-width: 100%;
      }
    }
  }
  h2 {
    font-size: 40px;
    font-weight: 700;
    color: var(--bg-primary);
    line-height: 1;
    // padding: 0 0 2rem 0;
    margin: 0;
    max-width: 900px;
  }
  p {
    font-size: 15px;
    line-height: 1.3;
  }
  > div {
    height: 119px;
    border-bottom: 1px solid #cfcfcf;
    display: flex;
    justify-content: start;
    align-items: center;
    &.border-none {
      border: none !important;
    }
  }
}

.trf-aboutus {
  .trf-clients {
    background: var(--bg-background);
  }
}

@media (min-width: 360px) and (max-width: 800px) {
  .trf-services {
    height: 100px;
    width: 100%;
  }
  .portrait {
    object-fit: cover;
    height: 200px;
  }
  .about-us-items {
    border-bottom: none;
    h2 {
      padding: 0;
      margin: 0;
      font-size: 1.6rem;
    }
    p {
      text-align: justify !important;
    }
    > div {
      height: auto;
      padding: 1rem 0;
      border: none;
    }
  }
}
</style>
