<template>
  <!-- <Component01 /> -->
  <section class="animate__animated animate__fadeInUp" v-if="pageData">
    <Component01
      template="Template-2"
      withItems="false"
      :title="pageData?.Title"
      :description="pageData?.Description"
    />
    <v-template v-for="s in servicesData">
      <Component04 v-if="s.Image?.data" :data="s" />
      <Component05 v-if="!s.Image?.data" :data="s" />
    </v-template>
  </section>
</template>

<script setup>
import Component01 from "../components/Component-01.vue";
import Component04 from "../components/Component-04.vue";
import Component05 from "../components/Component-05.vue";

import { onMounted, ref } from "vue";

import { useQuery } from "villus";

const pageQuery = `query Pages {
    pages(filters: { PageName: { eq: "Servicios" } }) {
        data {
            id
            attributes {
                PageName
                Slug
                Title
                Description
                MetaDescription
                MetaKeywords
                createdAt
                updatedAt
                publishedAt
                Components {
                    ... on ComponentPageSecServices {
                        Name
                        Services {
                            id
                            Name
                            Active
                            Description
                            Url
                            Right
                            Image {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                            Background {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
`;

const servicesData = ref(null);
const pageData = ref(null);

onMounted(async () => {
  const { data } = await useQuery({
    query: pageQuery,
  });

  pageData.value = data._rawValue.pages.data[0].attributes;
  servicesData.value = pageData.value.Components.find(
    (c) => c.Name === "Servicios"
  )?.Services;
});

const items = [
  {
    text: "Cinturones de seguridad para cada pasajero",
  },
  {
    text: "Los mejores frenos para el transporte de personal",
  },
  {
    text: "La correcta iluminación para los distintos climas durante el trayecto",
  },
  {
    text: "Un motor que brinda estabilidad y seguridad",
  },
  {
    text: "Aire acondicionado para comodidad durante el viaje",
  },
  {
    text: "Mantenimiento preventivo a todas nuestras unidades",
  },
];
</script>

<style lang="scss">
.trf-services {
  height: 100px;
  width: 100%;
}
</style>
