<template>
  <!-- <div class="overlay"></div> -->
  <section
    class="trf-slide"
    v-bind:style="{
      backgroundImage: !activeSlide?.Video?.data
        ? 'url(' + host + activeSlide?.Background?.data?.attributes?.url + ')'
        : '',
    }"
  >
    <video
      v-if="activeSlide?.Video"
      v-bind:src="host + activeSlide?.Video?.data?.attributes?.url"
      frameBorder="0"
      allowFullScreen
      allow="autoplay"
      autoplay
      loop
      muted
    ></video>

    <div class="trf-slide__content position-absolute bottom-0 w-100">
      <div class="container d-flex justify-content-between align-items-end">
        <div
          v-if="activeSlide && slides?.Slides.length"
          :class="
            'trf-slide__description ' +
            (activeSlide?.Video?.data && 'trf-slide__video')
          "
        >
          <template v-if="!activeSlide?.Video?.data">
            <h2 class="text-white font-inter">{{ activeSlide.Title }}</h2>
            <p class="text-white font-inter">
              {{ activeSlide.Description }}
            </p>
            <CcButton
              text="Más información"
              :link="activeSlide.Link"
              :template="'Template-1'"
            />
          </template>

          <template v-if="activeSlide?.Video?.data">
            <img
              src="../../../images/icons/video.png"
              @click="showVideo = true"
              alt="Ver Video"
            />
            <p class="text-white font-inter">
              {{ activeSlide.Description }}
            </p>
          </template>
        </div>
        <div class="trf-slide__actions">
          <div class="trf-slide__dots d-flex gap-2">
            <div
              v-for="(slide, index) in slides?.Slides"
              class="trf-slide__dot rounded-5"
              @click="setActive(index)"
              v-bind:class="activeIndex === index && 'active'"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <v-dialog v-model="showVideo" max-width="800" class="video-dialog">
    <img
      class="close-video"
      src="../../../images/icons/close.svg"
      @click="showVideo = false"
    />
    <video
      v-if="activeSlide?.VideoBackground?.data?.attributes?.url"
      v-bind:src="host + activeSlide?.VideoBackground?.data?.attributes?.url"
      frameBorder="0"
      allowFullScreen
      allow="autoplay"
      autoplay
      class="rounded-4"
    ></video>
  </v-dialog>
</template>

<script setup>
import CcButton from "./shared/CcButton.vue";
import defineProps from "vue";
import { onMounted, computed, ref } from "vue";
const host = "https://noseassapolio.online";

const props = defineProps({
  slides: Object,
});

const activeSlide = ref(null);
const activeIndex = ref(0);
const showVideo = ref(false);

onMounted(async () => {
  activeSlide.value = props.slides.Slides[0];
});

function setActive(index) {
  activeSlide.value = props.slides.Slides[index];
  activeIndex.value = index;
}
</script>

<style lang="scss">
.close-video {
  width: 30px;
  position: absolute;
  top: -17px;
  right: -17px;
  cursor: pointer;
}
.trf-slide {
  height: 100%;
  width: 100%;
  background-color: #012d46;
  // background-image: url(../../../images/gallery/portrait-bg.jpg);
  background-position: center;
  background-size: cover;
  overflow: hidden;

  video {
    position: absolute;
    left: 0;
    top: 0;
    background-size: cover;
    background: no-repeat center;
    user-select: none;
    pointer-events: none;
    filter: grayscale(0.8);
    height: 100%;
    min-width: 100%;
    min-height: 56.25vw;
    @media (min-width: 360px) and (max-width: 800px) {
      position: relative;
    }
  }

  &__actions {
    position: relative;
    z-index: 2;
  }

  &__content {
    z-index: 99;
    height: 250px;
  }
  &__description {
    width: 560px;

    h2 {
      font-size: 40px;
      font-weight: 700;
    }
    p {
      font-size: 17px;
      line-height: 1.2;
      font-weight: 300;
      margin: 0;
      padding: 5px 0 26px;
    }
  }

  &__video {
    width: 100%;
    position: relative;
    text-align: center;
    left: 50px;
    min-height: 191px;
    @media (min-width: 360px) and (max-width: 800px) {
      left: 30px;
    }
    img {
      width: 130px;
      margin-bottom: 1rem;
      cursor: pointer;
    }
    p {
      width: 400px;
      margin: 0 auto;
      @media (min-width: 360px) and (max-width: 800px) {
        width: 100%;
      }
    }
  }
  &__dot {
    width: 10px;
    height: 10px;
    background-color: white;
    &.active {
      width: 30px;
      background-color: var(--bg-primary-light) !important;
    }
    &:hover {
      background-color: #005f96;
      cursor: pointer;
    }
  }
}

@media (min-width: 360px) and (max-width: 800px) {
  .trf-slide {
    &__content {
      height: 300px;
    }
    &__description {
      h2 {
        font-size: 31px;
        font-weight: 600;
      }
      p {
        font-size: 14px;
      }
    }
  }
}
</style>
