<template>
  <a
    class="cc-button"
    v-if="!props.disabled"
    :class="props.template"
    :href="props.link"
  >
    {{ props.text }}
    <img
      v-if="!props.hideIcon"
      src="../../../../images/icons/more.svg"
      alt=""
    />
  </a>

  <a
    class="cc-button"
    v-if="props.disabled"
    :class="props.template"
    v-bind:class="props.disabled && 'disabled'"
    :href="props.link"
  >
    {{ props.text }}
    <img
      v-if="!props.hideIcon"
      src="../../../../images/icons/more.svg"
      alt=""
    />
  </a>
</template>

<script setup>
import defineProps from "vue";

const props = defineProps({
  template: String,
  text: String,
  hideIcon: Boolean,
  disabled: Boolean,
  link: {
    type: String,
    default: "#",
  },
});
</script>

<style lang="scss">
.cc-button {
  &.disabled {
    background: gray !important;
    opacity: 0.4 !important;
  }
  &.Template-1 {
    background-color: #005f96;
    text-decoration: none;
    color: white;
    padding: 10px;
    border-radius: 0 20px 20px 0;
    font-weight: 500;
  }
  &.Template-2 {
    background-color: #00c2cb;
    text-decoration: none;
    color: white;
    padding: 12px 15px 12px 18px;
    border-radius: 30px;
    font-weight: 500;
    font-size: 18px;
    img {
      margin-left: 10px;
    }
  }
  &.Template-3 {
    background-color: #005f96;
    text-decoration: none;
    color: white;
    padding: 10px;
    border-radius: 20px;
    font-weight: 500;
    font-size: 13px;
  }
  &.Template-4 {
    background-color: #005f96;
    border: 1px solid white;
    text-decoration: none;
    color: white;
    padding: 12px 30px;
    border-radius: 30px;
    font-weight: 500;
    font-size: 16px;
    img {
      display: none;
    }
  }
  &.Template-5 {
    background-color: #ffff;
    border: 1px solid white;
    text-decoration: none;
    color: #012d46;
    padding: 12px 20px;
    border-radius: 30px;
    font-weight: 600;
    font-size: 15px;
    width: auto;
    margin-top: 2rem;
    img {
      display: none;
    }
  }
  img {
    width: 14px;
    margin-left: 5px;
  }
}
</style>
