import { createRouter, createWebHistory } from "vue-router";
import IndexPage from "./vue/pages/Index.vue";
import ServicesPage from "./vue/pages/Services.vue";
import Clients from "./vue/pages/Clients.vue";
import HomePage from "./vue/pages/Home.vue";
import AboutUsPage from "./vue/pages/AboutUs.vue";
import VehiclesPage from "./vue/pages/Vehicles.vue";
import ContactPage from "./vue/pages/Contact.vue";
import BasePage from "./vue/pages/Base.vue";
import ClaimsBook from "./vue/pages/ClaimsBook.vue";

const routes = [
  {
    path: "",
    component: BasePage,
    children: [
      {
        path: "",
        component: HomePage,
      },
      {
        path: "/inicio",
        component: HomePage,
      },
      {
        path: "/servicios",
        component: ServicesPage,
      },
      {
        path: "/flota",
        component: VehiclesPage,
      },
      {
        path: "/nosotros",
        component: AboutUsPage,
      },
      {
        path: "/clientes",
        component: Clients,
      },
      {
        path: "/contactanos",
        component: ContactPage,
      },
      {
        path: "/libro-de-reclamaciones",
        component: ClaimsBook,
      },
    ],
  },
];

export default createRouter({
  history: createWebHistory(),
  routes,
});
